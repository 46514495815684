<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Enviar CFDI y XML</h1>

    <h3 class="form-division">Información general</h3>

    <b-field
      label="Correo a usar para enviar orden de compra (Requerido)"
      expanded
      label-position="inside"
      class="special-margin"
      :type="{ 'is-danger': error.EMAIL }"
      :message="{
        'El correo no es válido': error.EMAIL,
      }"
    >
      <b-select
        placeholder="Ejemplo: Proveedor"
        icon="at"
        expanded
        v-model="newSendEmail.EMAIL"
      >
        <option value="" disabled>selecciona un correo</option>
        <option
          v-for="singleEmail in companyEmails"
          :value="singleEmail._id"
          :key="singleEmail._id"
        >
          {{ singleEmail.CORREO }}
        </option>
      </b-select>
    </b-field>

    <b-field
      label="Enviar CFDI y XML a estos correos (Opcional)"
      expanded
      label-position="inside"
      class="special-margin"
      :type="{ 'is-danger': error.DESTINOS }"
      :message="{
        'Los correos no son válidos': error.DESTINOS,
      }"
    >
      <b-taginput
        v-model="newSendEmail.DESTINOS"
        ellipsis
        icon="email-multiple-outline"
        placeholder="Enviar recordatorios también a estos correos"
        :before-adding="(tagToAdd) => validateEmail(tagToAdd)"
      >
      </b-taginput>
    </b-field>

    <div class="add-global-controls">
      <b-button type="is-success" @click="sendEmails()"
        >Enviar CFDI y XML</b-button
      >

      <b-button
        type="is-success"
        @click="downloaInvoice()"
        v-if="
          action === 'SENDCFDI' || action === 'SENDPROFESSIONALSERVICESCFDI'
        "
        >Descargar CFDI</b-button
      >

      <b-button
        type="is-danger"
        @click="closeModal()"
        class="cancel-button"
        v-if="!hideClose"
        >Regresar</b-button
      >

      <b-button
        type="is-danger"
        @click="closeParentModal()"
        class="cancel-button"
        v-if="hideClose"
        >Cerrar</b-button
      >
    </div>
  </div>
</template>

<script>
import { checkValidationErrors, validateFields } from "../../../utils/fns";
import { EventBus } from "../../../event-bus.js";
// @ is an alias to /src
export default {
  name: "SendCFDIModal",
  props: [
    "saleId",
    "invoiceId",
    "creditNoteId",
    "porteId",
    "paymentId",
    "action",
    "hideClose",
  ],
  components: {},
  data() {
    return {
      newSendEmail: {
        EMAIL: "",
        DESTINOS: [],
      },
      error: {
        EMAIL: false,
        DESTINOS: false,
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    closeParentModal() {
      EventBus.$emit("closeParentModal", {});
    },
    async sendEmails() {
      this.error = validateFields(this.newSendEmail, this.error);
      if (checkValidationErrors(this.error)) {
        const response = await this.$store.dispatch(this.action, {
          invoiceId: this.invoiceId,
          saleId: this.saleId,
          creditNoteId: this.creditNoteId,
          porteId: this.porteId,
          paymentId: this.paymentId,
          selectedEmail: this.newSendEmail.EMAIL,
          emails: this.newSendEmail.DESTINOS,
        });
        if (response.success) {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se envió exitosamente el CFDI",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al enviar el CFDI",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } else {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al enviar el CFDI, revise los campos obligatorios",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    validateEmail(email) {
      const re =
        //eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    async downloaInvoice() {
      try {
        let response = await this.$store.dispatch(
          this.action === "SENDCFDI"
            ? "GETINVOICEPDF"
            : "GETPROFESSIONALSERVICESINVOICEPDF",
          {
            invoiceId: this.invoiceId,
            saleId: this.saleId,
          }
        );
        if (!response.success) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al descargar el CFDI`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        const linkSource = `data:application/pdf;base64,${response.pdfData}`;
        const downloadLink = document.createElement("a");
        const fileName = response.pdfName;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al descargar el CFDI`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {
    companyEmails() {
      return this.$store.getters.COMPANIES_EMAILS;
    },
  },
  async created() {},
  mounted() {},
  watch: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
