const creditNoteRelations = [
  {
    CLAVE: "01",
    DESCRIPCION: "Notas de Crédito de Documentos Relacionados",
  },
  {
    CLAVE: "02",
    DESCRIPCION: "Notas de Débito de los Documentos Relacionados",
  },
  {
    CLAVE: "03",
    DESCRIPCION: "Devolución de Mercancías sobre Facturas o Traslados Previos",
  },
  {
    CLAVE: "04",
    DESCRIPCION: "Sustitución de los CFDI Previos",
  },
  {
    CLAVE: "05",
    DESCRIPCION: "Traslados de Mercancías Facturados Previamente",
  },
  {
    CLAVE: "06",
    DESCRIPCION: "Factura Generada por los Traslados Previos",
  },
  {
    CLAVE: "07",
    DESCRIPCION: "CFDI por Aplicación de Anticipo",
  },
  {
    CLAVE: "08",
    DESCRIPCION: "Facturas Generadas por Pagos en Parcialidades",
  },
  {
    CLAVE: "09",
    DESCRIPCION: "Factura Generada por Pagos Diferidos",
  },
];

export default creditNoteRelations;
