const cfdiCancellationReasons = [
  {
    CLAVE: "02",
    DESCRIPCION: "Comprobante emitido con errores sin relación.",
  },
  {
    CLAVE: "03",
    DESCRIPCION: "No se llevó a cabo la operación.",
  },
  {
    CLAVE: "04",
    DESCRIPCION: "Operación nominativa relacionada en una factura global.",
  },
];

export default cfdiCancellationReasons;
