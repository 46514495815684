<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Notas de crédito</h1>

    <div class="invoice-div-bottom-div">
      <div class="table-header providers-list-global table-header-border">
        Cantidad de notas de crédito:
        <b>{{ currentObjects.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="currentObjects"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="sales"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de nota de crédito`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="desc"
        checkbox-position="left"
        default-sort="CLAVE_NOTAC"
      >
        <template #empty>
          <div class="has-text-centered">No hay notas de crédito</div>
        </template>

        <template slot-scope="props">
          <b-table-column label="Folio" field="CLAVE_NOTAC" sortable>
            <span v-if="!checkIfValid(props.row.CLAVE_NOTAC)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.CLAVE_NOTAC }}</span>
          </b-table-column>

          <b-table-column label="Estatus" field="STATUS" sortable>
            <span v-if="!checkIfValid(props.row.STATUS)">{{
              "Sin información"
            }}</span>
            <span v-if="props.row.STATUS === 'AC'">Activo</span>
            <span v-if="props.row.STATUS === 'CA'">Cancelada</span>
          </b-table-column>

          <b-table-column
            label="Estatus de timbrado"
            field="STATUS_FAC"
            sortable
          >
            <span v-if="!checkIfValid(props.row.STATUS_FAC)">{{
              "Sin información"
            }}</span>

            <span v-if="props.row.STATUS_FAC === 'SF'">Sin Timbrar</span>
            <span v-if="props.row.STATUS_FAC === 'PF'"
              >Parcialmente timbrada</span
            >
            <span v-if="props.row.STATUS_FAC === 'PP'"
              >Pendiente de timbrar</span
            >
            <span v-if="props.row.STATUS_FAC === 'TI'">Timbrada</span>
            <span v-if="props.row.STATUS_FAC === 'CA'">Cancelada</span>
          </b-table-column>

          <b-table-column label="Fecha" field="formatedDate" sortable>
            <span v-if="!checkIfValid(props.row.formatedDate)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.formatedDate)">{{
              props.row.formatedDate
            }}</span>
          </b-table-column>

          <b-table-column label="Tipo" field="TIPO" sortable>
            <span v-if="!checkIfValid(props.row.TIPO)">{{
              "Sin información"
            }}</span>
            <span v-if="props.row.TIPO === 'I'">Importe</span>
            <span v-if="props.row.TIPO === 'D'">Devolución</span>
            <span v-if="props.row.TIPO === 'A'">Amortización</span>
          </b-table-column>

          <b-table-column label="Sub total" field="SUB_TOTAL" sortable>
            <span v-if="!checkIfValid(props.row.SUB_TOTAL)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.SUB_TOTAL)">{{
              props.row.SUB_TOTAL | money("MXN", 2)
            }}</span>
          </b-table-column>

          <b-table-column label="IVA" field="IVA_GTO" sortable>
            <span v-if="!checkIfValid(props.row.IVA_GTO)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.IVA_GTO)">{{
              props.row.IVA_GTO | money("MXN", 2)
            }}</span>
          </b-table-column>

          <b-table-column label="IEPS" field="IEPS_GTO" sortable>
            <span v-if="!checkIfValid(props.row.IEPS_GTO)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.IEPS_GTO)">{{
              props.row.IEPS_GTO | money("MXN", 2)
            }}</span>
          </b-table-column>

          <b-table-column label="Total" field="TOTAL_GTO" sortable>
            <span v-if="!checkIfValid(props.row.TOTAL_GTO)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.TOTAL_GTO)">{{
              props.row.TOTAL_GTO | money("MXN", 2)
            }}</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <div class="controls">
                  <b-button
                    type="is-primary"
                    @click="showCreditNote(props.row)"
                    v-if="props.row.STATUS_FAC === 'SF'"
                    >Ver o timbrar nota de crédito</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="showCreditNote(props.row)"
                    v-if="props.row.STATUS_FAC !== 'SF'"
                    >Ver nota de crédito</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="cancelCreditNoteDocument(props.row)"
                    v-if="
                      props.row.STATUS !== 'CA' && props.row.STATUS_FAC === 'TI'
                    "
                    >Cancelar nota de crédito</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="
                      startCancelEvent(
                        'danger',
                        function () {
                          cancelCreditNoteDocmentNoInvoice(props.row);
                        },
                        'cancelar nota de crédito'
                      )
                    "
                    v-if="
                      props.row.STATUS !== 'CA' && props.row.STATUS_FAC !== 'TI'
                    "
                    >Cancelar nota de crédito</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="showAcuseXML(props.row._id)"
                    v-if="props.row.STATUS_FAC === 'CA'"
                    >Ver acuse de cancelación</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="showCreditNoteXML(props.row._id)"
                    v-if="props.row.STATUS_FAC === 'TI'"
                    >Ver XML</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="downloadCreditNotePDF(props.row._id)"
                    v-if="props.row.STATUS_FAC === 'TI'"
                    >Descargar PDF</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="openSendCFDI(props.row._id)"
                    v-if="props.row.STATUS_FAC === 'TI'"
                    >Enviar CFDI y XML</b-button
                  >
                </div>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import AddGlobalCreditNote from "./AddGlobalCreditNote";
import { openConfirmationModalDanger } from "../../../utils/component-fns";
import XMLViewer from "../../Accounting/components/XMLViewer.vue";
import moment from "moment";
import GlobalCreditNoteCancellationModal from "./GlobalCreditNoteCancellationModal.vue";
import SendCFDIModal from "../../Accounting/components/SendCFDIModal";
import { EventBus } from "../../../event-bus.js";

export default {
  name: "GlobalCreditNotesDetails",
  props: ["invoiceInformation"],
  components: {},
  data() {
    return {
      currentMovements: [],
      perPage: 25,
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      TOTAL_PAGADO: 0,
    };
  },
  mounted() {
    moment.locale("es");

    EventBus.$on("closeParetModal", () => {
      this.closeModal();
    });
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    closeParentModal() {
      EventBus.$emit("closeParetModal");
    },
    showCreditNote(creditNoteInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddGlobalCreditNote,
        props: {
          objectInformation: creditNoteInformation,
          invoiceInformation: this.invoiceInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async downloadCreditNotePDF(creditNoteId) {
      try {
        let response = await this.$store.dispatch("GETCREDITNOTEPDF", {
          creditNoteId: creditNoteId,
        });
        if (!response.success) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al descargar el pdf`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        const linkSource = `data:application/pdf;base64,${response.pdfData}`;
        const downloadLink = document.createElement("a");
        const fileName = response.pdfName;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al descargar el pdf`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async showCreditNoteXML(creditNoteId) {
      try {
        let response = await this.$store.dispatch("GETCREDITNOTEXML", {
          creditNoteId: creditNoteId,
        });
        if (!response.success) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al descargar el xml`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        this.$buefy.modal.open({
          canCancel: ["x"],
          parent: this,
          component: XMLViewer,
          props: {
            xmlData: response.xmlData,
            xmlName: response.xmlName,
          },
          hasModalCard: false,
          customClass: "primary-modal-class",
          trapFocus: true,
          fullScreen: false,
          destroyOnHide: true,
        });
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al descargar el xml`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async cancelCreditNoteDocument(creditNote) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: GlobalCreditNoteCancellationModal,
        props: {
          invoiceId: this.invoiceInformation._id,
          creditNoteId: creditNote._id,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async showAcuseXML(creditNoteId) {
      try {
        let response = await this.$store.dispatch("GETACUSECREDITNOTEXML", {
          creditNoteId: creditNoteId,
        });
        if (!response.success) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al descargar el acuse de la nota de crédito`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        this.$buefy.modal.open({
          canCancel: ["x"],
          parent: this,
          component: XMLViewer,
          props: {
            xmlData: response.xmlData,
            xmlName: response.xmlName,
          },
          hasModalCard: false,
          customClass: "primary-modal-class",
          trapFocus: true,
          fullScreen: false,
          destroyOnHide: true,
        });
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al descargar el acuse de la nota de crédito`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    openSendCFDI(creditNoteId) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SendCFDIModal,
        props: {
          creditNoteId: creditNoteId,
          action: "SENDCFDICREDITNOTE",
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param dangerMessage the message to display to the user
     */
    startCancelEvent(type, functionOnConfirm, dangerMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalDanger.call(
        this,
        type,
        functionOnConfirm,
        dangerMessage
      );
    },
    async cancelCreditNoteDocmentNoInvoice(creditNote) {
      try {
        let response = await this.$store.dispatch(
          "CANCELGLOBALCREDITNOTEDOCUMENT",
          {
            creditNoteId: creditNote._id,
            invoiceId: this.invoiceInformation._id,
          }
        );
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se canceló exitosamente la nota de crédito",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
          this.closeParentModal();
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al cancelar la nota de crédito en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al cancelar la nota de crédito en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {
    currentObjects() {
      return this.invoiceInformation.NOTAC.map((singleClarification) => {
        const singleClarificationWithProps = { ...singleClarification };
        singleClarificationWithProps.formatedDate = moment(
          singleClarification.FECHA
        ).format("DD-MM-YYYY HH:mm");
        return singleClarificationWithProps;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
